import React from "react";
import { Link, useParams } from "react-router-dom";

export default function HotelRoomTab({ HotelRoom,hotel }) {
    const {tour_op_id} = useParams()
  return (
    <div className="flex flex-col gap-2">
      {HotelRoom.map((room, index) => {
        const { Name, RoomCategory, RoomOccupancy } = room;
        return (
          <section key={index} className="grid grid-cols-4 items-center">
            <p>
              <span className="font-bold">Nombre:</span> {Name[0]}
            </p>
            <p>
              <span className="font-bold">Categoría:</span>{" "}
              {RoomCategory[0]?._ || "-"}
            </p>
            <p>
              <span className="font-bold">Máximo:</span>{" "}
              {RoomOccupancy[0].$.MaxOccupancy}
            </p>
            <Link to={`/${tour_op_id}/hotels-request`} className="button bg-brightBlue text-white text-center" state={{ hotelOffer: hotel }}>
              Reservar
            </Link>
          </section>
        );
      })}
    </div>
  );
}
