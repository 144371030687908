import React, { useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import oricode_chevron from "../../../../iconos/oricode_chevron.png";
import { AutoComplete } from "../../../../utils/AutoComplete";

export default function HotelCitySearch({
  setValue,
  register,

  errors,
  
}) {
  const [descodeInput, setDescodeInput] = useState("");
  return (
    <div className="flex flex-col relative">
      <span className="flex gap-2 items-center justify-center">
        <img src={oricode_chevron} alt="oricode_chevron" className="w-4" />
        <p>Ciudad</p>
      </span>
       <AutoComplete
        justCities
        notIcon
        classname={"bg-[#EFEFEF] outline-none py-2 w-[306px] px-2"}
        register={register}
        name="cityName"
        placeholder="Ciudad de Destino"
        handleAction={(d) => {
          //setValue("cityCode", d?.iata);
          setValue("cityName", d)
        }}
        inputState={[descodeInput, setDescodeInput]}
      /> 
      
      <div className="absolute text-red-600 text-xs -bottom-1 left-4 whitespace-nowrap">
        <ErrorMessage errors={errors} name="cityName" as="p" />
      </div>
    </div>
  );
}
