import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import walltrip_logo from "../../iconos/logos/walltrip.png";
import mini_walltrip_logo from "../../iconos/mini_walltrip_logo.png";

// import config from "../../iconos/user.png";
import { sidebarOptions } from "./sidebarOptions";
import clsx from "clsx";
import { saveTourOperator } from "../../utils/saveTourOperator";
import SidebarPanelSearcher from "./SidebarPanelSearcher";
// import useAgencies from "../../hooks/useAgencies";
import { useEffect } from "react";
import MenuLink from "./MenuLink";
import UserPanel from "./UserPanel";

export const SidebarPanel = ({ hoverState }) => {
  const [isHovered, setIsHovered] = hoverState;
  const { tour_op_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const userData = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData")
  );
  // const { agencies, getAgenciesByTourOperator } = useAgencies();

  useEffect(() => {
    // DESCOMENTAR EL DÍA QUE SE IMPLEMENTE EL LOGO DE AGENCIA
    // getAgenciesByTourOperator();

    // REDIRECCIÓN A 'LOGIN' EN CASO DE NO TENES SESIÓN EN SUPERADMIN
    if (!userData) {
      navigate(`/${tour_op_id}/login`);
      return;
    }

    // REDIRECCIÓN A HOME EN CASO DE ROL NO PERMITIDO EN SUPERADMIN
    if (userData?.role_id === 3) {
      navigate(`/${tour_op_id}/flights`);
      return;
    }
  }, [userData, tour_op_id, navigate]);

  return (
    <aside
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={clsx(
        "fixed flex flex-col transition-[width] duration-500 ease-in-out h-[100vh] mr-3 rounded-r-[25px] py-4 px-4 bg-white  scrollbar-hide z-custom overflow-y-scroll z-custom overflow-x: visible", //z-custom overflow-y-scroll
        {
          "w-[92px]": !isHovered,
        },
        {
          "w-[237px]": isHovered,
        }
      )}
    >
      <Link to={`/${tour_op_id || "walltrip"}/flights`}>
        <div>
          {isHovered ? (
            <img
              src={walltrip_logo}
              alt="walltrip"
              className="w-37 h-11 mx-auto"
            />
          ) : (
            <img
              src={mini_walltrip_logo}
              alt="walltrip"
              className="w-13 h-11 mx-auto"
            />
          )}
        </div>
      </Link>

      <section className="flex h-full flex-col justify-between">
        {/* options links */}
        <div>
          <ul className="flex flex-col gap-2 my-8 ">
            <SidebarPanelSearcher isHovered={isHovered} />
            {sidebarOptions.map((option, index) => (
              <li className="relative " key={index}>
                {location.pathname.includes(option.link) ? (
                  <div className="absolute -left-4 bg-[#242423] h-6 mt-2 w-[5px] rounded-r-[6px]" />
                ) : null}
                <MenuLink {...option} isHovered={isHovered} />
              </li>
            ))}
          </ul>
          <div className="flex flex-col justify-between">
           

      
          </div>
        </div>

        <div className="z-panel">
          <UserPanel
            tour_op_id={tour_op_id}
            location={location}
            userData={userData}
            tourOP={tourOP}
            isHovered={isHovered}
          />
        </div>
      </section>
    </aside>
  );
};
