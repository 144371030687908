import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { usePackages } from "../../../hooks/usePackages";
import SEO from "../../../utils/SEO";
import { AutoComplete } from "../../../utils/AutoComplete";
import PortraitHandler from "./subForms/PortraitHandler";
import FormTitle from "../../../utils/FormTitle";

export const FormEditPackage = () => {
  const { tour_op_id, package_id } = useParams();
  const { individualPackage, getPackage, updatePackage } = usePackages();
  const [selectedPackage, setSelectedPackage] = useState();
  const [rangeDate, setRangeDate] = useState();
  const { register, handleSubmit, setValue, watch } = useForm();
  const [oricodeInput, setOricodeInput] = useState(
    selectedPackage?.origin_code ?? ""
  );
  const [descodeInput, setDescodeInput] = useState(
    selectedPackage?.destination_code ?? ""
  );
  const [pictureError, setPictureError] = useState(false);
  const [show, setShow] = useState();
  const [flyerDataUrl, setFlyerDataUrl] = useState(null);
  const [pictures, setPictures] = useState([]);
  const [portrait, setPortrait] = useState();
  const [newPictures, setNewPictures] = useState([]);
  const [deletedPictures, setDeletedPictures] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getPackage(package_id);
  }, []);

  useEffect(() => {
    setSelectedPackage(individualPackage);
    setShow(individualPackage.show);
    setPortrait(individualPackage.portrait);
  }, [individualPackage, package_id]);

  useEffect(() => {
    if (selectedPackage) {
      setRangeDate({
        from: selectedPackage.departure_date,
        to: selectedPackage.return_date,
      });
      !flyerDataUrl && setFlyerDataUrl(selectedPackage?.flyer);
      setPictures(
        individualPackage.pictures?.map((picture) => picture.url) || []
      );
    }
  }, [selectedPackage]);
  console.log(show);
  const onSubmitEdit = async (data) => {
    toast.promise(
      updatePackage({
        ...selectedPackage,
        origin_code:
          data.packageSchema.origin_code || selectedPackage?.origin_code,
        destination_code:
          data.packageSchema.destination_code ||
          selectedPackage?.destination_code,
        departure_date: rangeDate.from,
        return_date: rangeDate.to,
        arrival_date: rangeDate.to,
        portrait: portrait,
        flyer: flyerDataUrl,
        description: selectedPackage.description,
        new_pictures: newPictures,
        show: show,
        deleted_pictures: deletedPictures,
      }).then(() =>
        navigate(`/${tour_op_id}/panel/packages/my-packages`, { replace: true })
      ),
      {
        loading: "Cargando...",
        error: <b>Hubo un error, intenta de nuevo.</b>,
      }
    );
  };

  const MAX_SIZE_MB = 2; // Tamaño máximo del flyer y fotos

  // Comprimir imagen individual
  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_WIDTH = 800;
          const scaleSize = MAX_WIDTH / image.width;
          canvas.width = MAX_WIDTH;
          canvas.height = image.height * scaleSize;

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          let quality = 0.9;
          let compressedDataUrl = canvas.toDataURL("image/jpeg", quality);

          while (
            compressedDataUrl.length / 1024 / 1024 > MAX_SIZE_MB &&
            quality > 0.1
          ) {
            quality -= 0.1;
            compressedDataUrl = canvas.toDataURL("image/jpeg", quality);
          }

          resolve(compressedDataUrl);
        };
      };
      reader.readAsDataURL(file);
    });
  };

  // Manejador para la carga de imágenes del flyer
  const handleFlayerChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const compressedFlyer = await compressImage(file);
      setFlyerDataUrl(compressedFlyer);
      setValue("packageSchema.flyer", compressedFlyer);
    }
  };
  const handlePortraitChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const compressedPortrait = await compressImage(file);
      setPortrait(compressedPortrait);
      setValue("packageSchema.portrait", compressedPortrait);
    }
  };

  //Manejador para la carga de múltiples imágenes del paquete
  const handlePicturesChange = async (event) => {
    const files = Array.from(event.target.files); // Convertir a array
    const compressedImages = await Promise.all(
      files.map((file) => compressImage(file)) // Comprimir cada imagen
    );

    const validImages = compressedImages.filter((img) => img !== null); // Filtrar imágenes válidas

    // Actualizar el estado de imágenes
    setPictures((prevPictures) => {
      const updatedPictures = [...prevPictures, ...validImages];

      // Actualizar el valor del formulario solo con las URLs (strings)
      setValue("packageSchema.pictures", updatedPictures.slice(1)); // Esto ya contiene solo strings
      return updatedPictures; // Retornar el nuevo estado
    });

    setNewPictures((prevNewPictures) => [
      ...(prevNewPictures || []), // Mantener las imágenes existentes
      ...validImages, // Agregar las nuevas imágenes válidas como URLs
    ]);

    // Reiniciar el input
    event.target.value = null;
  };

  function removePictureAtIndex(indexToRemove) {
    // Access the current pictures before updating
    setPictures((prevPictures) => {
      const deletedImage = prevPictures[indexToRemove]; // Find the picture being removed

      // Only add the image to deletedPictures if it's part of the original package's pictures
      setDeletedPictures((prevDeletedPictures) => {
        const isOriginalPicture = individualPackage.pictures.some(
          (originalPicture) => originalPicture.url === deletedImage
        );

        if (isOriginalPicture) {
          const originalPicture = individualPackage.pictures.find(
            (picture) => picture.url === deletedImage
          );

          // Add the picture to deletedPictures with its original id and url
          return [
            ...prevDeletedPictures,
            {
              id: originalPicture.id, // Use the original ID
              url: originalPicture.url, // Use the original URL
            },
          ];
        }

        return prevDeletedPictures; // If it's not in original pictures, do nothing
      });

      // Return the updated pictures array without the removed image
      return prevPictures.filter((_, index) => index !== indexToRemove);
    });

    // Also remove the picture from newPictures if it's there
    setNewPictures((prevNewPictures) => {
      return prevNewPictures.filter((_, index) => index !== indexToRemove);
    });
  }

  const handleEdit = (e) => {
    setSelectedPackage((curr) => {
      return {
        ...curr,
        [e.target.name]: e.target.value,
      };
    });
    if (pictureError) return setPictureError(false);
  };

  return (
    <>
      <SEO title={"Editar Paquete"} />
      <div className="w-full">
        <FormTitle
          route={"panel/packages/my-packages"}
          title={" Editar Paquete"}
          btnText={"Volver a Mis Paquetes"}
        />
        <form onSubmit={handleSubmit(onSubmitEdit)}>
          <div>
            <Toaster />
          </div>
          <div className="grid grid-cols-12 gap-12 my-10 mt-16 mx-auto max-w-[1200px] bg-white rounded-lg p-8 ">
            <section className="flex flex-col gap-4 col-span-5">
              <h2 className="text-darkBlue font-medium">INFORMACIÓN GENERAL</h2>
              <div className="grid grid-cols-2 gap-4">
                <label className="col-span-2 relative">
                  <input
                    placeholder="Ingresar el nombre completo del paquete"
                    className="addPackInput w-full"
                    name="title"
                    value={selectedPackage?.title}
                    onChange={handleEdit}
                  />
                </label>

                <label className="relative">
                  <AutoComplete
                    justCities
                    notIcon
                    classname="addPackInput w-full"
                    defaultValue={selectedPackage?.origin_code}
                    register={register}
                    name="packageSchema.origin_code"
                    placeholder={selectedPackage?.origin_code}
                    handleAction={(d) =>
                      setValue("packageSchema.origin_code", d.toUpperCase())
                    }
                    inputState={[oricodeInput, setOricodeInput]}
                  />
                </label>

                <label className="relative">
                  <AutoComplete
                    justCities
                    notIcon
                    classname="addPackInput w-full"
                    register={register}
                    name="packageSchema.destination_code"
                    defaultValue={selectedPackage?.destination_code}
                    placeholder={selectedPackage?.destination_code}
                    handleAction={(d) =>
                      setValue(
                        "packageSchema.destination_code",
                        d.toUpperCase()
                      )
                    }
                    inputState={[descodeInput, setDescodeInput]}
                  />
                </label>

                <div className="flex flex-col col-span-2">
                  <label className="text-darkBlue mt-3 my-2 relative w-full">
                    <p className="font-medium">DESCRIPCIÓN</p>
                    <textarea
                      id="description_general"
                      name="description_general"
                      value={selectedPackage?.description_general}
                      onChange={handleEdit}
                      className="addPackInput w-full"
                    />
                  </label>
                </div>
              </div>
              <div className="flex flex-col col-span-2">
                <label className="text-darkBlue mt-3 my-2 relative w-full">
                  <p className="font-medium">INCLUYE</p>
                  <textarea
                    id="include"
                    name="include"
                    value={selectedPackage?.include}
                    onChange={handleEdit}
                    className="addPackInput w-full"
                  />
                </label>
              </div>
              <div className="flex flex-col col-span-2">
                <label className="text-darkBlue mt-3 my-2 relative w-full">
                  <p className="font-medium">NO INCLUYE</p>
                  <textarea
                    id="not_included"
                    name="not_included"
                    value={selectedPackage?.not_included}
                    onChange={handleEdit}
                    className="addPackInput w-full"
                  />
                </label>
              </div>
            </section>

            <section className="relative col-span-7 pb-16">
              <div className="flex flex-col col-span-2">
                <label className="text-darkBlue mt-3 my-2 relative w-full">
                  <p className="font-medium">ITINERARIO</p>
                  <textarea
                    id="itinerary"
                    name="itinerary"
                    value={selectedPackage?.itinerary}
                    onChange={handleEdit}
                    className="addPackInput w-full"
                  />
                </label>
              </div>
              <div className="flex flex-col col-span-2">
                <label className="text-darkBlue mt-3 my-2 relative w-full">
                  <p className="font-medium">TERMINOS</p>
                  <textarea
                    id="terms"
                    name="terms"
                    value={selectedPackage?.terms}
                    onChange={handleEdit}
                    className="addPackInput w-full"
                  />
                </label>
              </div>
              <h2 className="text-darkBlue font-medium mb-4">
                PORTADA DEL PAQUETE
              </h2>
              <div className="flex flex-col gap-2 items-center mb-4">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePortraitChange}
                  className="addPackInput w-full"
                />
              </div>
              <div>
                <PortraitHandler
                  portrait={portrait}
                  setPortrait={setPortrait}
                />
              </div>
              <h2 className="text-darkBlue font-medium mb-4">
                FOTOS DEL PAQUETE
              </h2>
              <div className="flex flex-col gap-2 items-center mb-4">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePicturesChange}
                  multiple // Permite cargar múltiples imágenes
                  className="addPackInput w-full"
                />
              </div>
              <div>
                <section className="flex flex-wrap gap-6 w-full">
                  {pictures.map((url, index) => (
                    <div key={index} className="flex flex-col items-center">
                      <img
                        src={url}
                        alt={`Imagen ${index}`}
                        className="max-w-[16rem] max-h-[500px]"
                      />
                      <button
                        type="button"
                        onClick={() => removePictureAtIndex(index)}
                        className="rounded py-1 px-4 border border-red-500 mt-2"
                      >
                        Eliminar
                      </button>
                    </div>
                  ))}
                </section>
              </div>
              <h2 className="text-darkBlue font-medium my-4">FLYER</h2>
              <div className="flex flex-col gap-2 items-center mb-4">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFlayerChange}
                  className="addPackInput w-full"
                />
                {flyerDataUrl && (
                  <>
                    <img
                      src={flyerDataUrl}
                      alt="Selected"
                      className="max-w-[16rem] max-h-[500px]"
                    />
                    <button
                      type="button"
                      onClick={(e) => setFlyerDataUrl(null)}
                      className="rounded py-1 px-4 border border-red-500 mt-2"
                    >
                      Eliminar
                    </button>
                  </>
                )}
              </div>

              <div className="flex flex-row gap-3">
                <label className="text-darkBlue font-medium my-4">
                  PUBLICAR
                </label>
                <input
                  type="checkbox"
                  name="show"
                  defaultChecked={show}
                  className="scale-150"
                  onClick={(e) => setShow(!show)}
                />
              </div>
              <button className="button ml-auto absolute bottom-0 right-0 text-lg w-1/2 bg-darkBlue text-white hover:shadow-lg transition-all">
                Actualizar Paquete
              </button>
            </section>
          </div>
        </form>
      </div>
    </>
  );
};
