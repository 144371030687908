import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { hotelSchema } from "../../../schemas/hotelSchema";
import { useBookings } from "../../../hooks/useBookings";
import toast, { Toaster } from "react-hot-toast";
import SEO from "../../../utils/SEO";
import Head from "../../head";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";

export default function HotelRequest() {
  const location = useLocation();
  const { hotelOffer } = location.state || {};
  const { HotelInfo, HotelOptions, checkInDay, checkOutDay } = hotelOffer;
  const { Prices, HotelRooms } = HotelOptions[0].HotelOption[0];
  const { $, TotalFixAmounts } = Prices?.[0].Price[0];
  const { RoomCategory, RoomOccupancy } = HotelRooms?.[0].HotelRoom?.[0];
  const { Adults, Children } = RoomOccupancy?.[0].$;

  const { postHotelBooking } = useBookings(); // Call the postHotelBooking function from the useBookings hook

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(hotelSchema),
  });

  const onSubmit = (data) => {
    let body = {
      booking: {
        creation_date: new Date().toISOString(),
        status: "pending",
        comments: "No comments",
        type: "Hotels",
        tour_op_id: 1,
        currency_type_id: 1,
        agency_id: 1,
        user_id: 1,
      },
      hotel: {
        data: JSON.stringify(hotelOffer),
      },
      travelers: [
        {
          data: {
            first_name: data.data.first_name,
            last_name: data.data.last_name,
            date_of_birth: data.data.date_of_birth,
            gender: data.data.gender,
            email_address: data.data.email_address,
          },
          documentation: {
            doc_type: data.documentation.doc_type,
            birth_place: data.documentation.birth_place,
            issuance_location: data.documentation.issuance_location,
            issuance_date: data.documentation.issuance_date,
            number: data.documentation.number,
            expiry_date: data.documentation.expiry_date,
            issuance_country: data.documentation.issuance_country,
            validity_country: data.documentation.issuance_country,
            nationality: data.documentation.nationality,
            holder: true,
            traveler_id: 1,
          },
          contact_phones: [
            {
              device_type: data.contact_phones[0].device_type,
              country_calling_code: data.contact_phones[0].country_calling_code,
              number: data.contact_phones[0].number,
              traveler_id: 1,
            },
          ],
        },
      ],
      user_id: 1,
    };

    console.log(body);

    toast.promise(postHotelBooking(body), {
      loading: "Cargando...",
      success: <b>Enviado con exito!</b>,
      error: <b>Hubo un error, intenta de nuevo.</b>,
    });
  };

  return (
    <main className="font-body">
      <SEO title={"Hoteles"} />
      <Head />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="max-w-[1100px] mx-auto my-8 flex justify-between gap-3"
      >
        <div>
          <Toaster />
        </div>
        <div
          className="grid grid-cols-3 items-center gap-x-4 gap-y-8 origin-top transition-all duration-300 p-4 border border-gray2 rounded-lg overflow-hidden"
          id="Pasajero 1 (Adulto)-body"
        >
          <label
            htmlFor="name"
            className="mb-1 font-low text-sm text-[#242423]"
          >
            Nombre
          </label>
          <div className="relative col-span-2">
            <input
              name="name"
              className="w-full rounded-md px-4 py-2 text-xs text-black focus:outline-none placeholder:text-[#24242399] bg-lightGray3"
              placeholder="Como figura en el documento de viaje"
              id="name"
              {...register("data.first_name")}
            />
            {errors.data?.first_name && (
              <p className="text-red-500">Este campo es necesario</p>
            )}
          </div>
          <label
            htmlFor="surname"
            className="mb-1 font-low text-sm text-[#242423]"
          >
            Apellido
          </label>
          <div className="col-span-2 relative">
            <input
              name="surname"
              className="w-full rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
              placeholder="Como figura en el documento de viaje"
              id="surname"
              {...register("data.last_name")}
            />
            {errors.data?.last_name && (
              <p className="text-red-500">Este campo es necesario</p>
            )}
          </div>
          <label
            className="mb-1 font-low text-sm text-[#242423]"
            htmlFor="birthday"
          >
            Fecha de nacimiento
          </label>
          <div className="col-span-2 relative">
            <input
              name="birthday"
              type="date"
              className="w-full rounded-md px-4 py-2 text-xs text-black focus:outline-none placeholder:text-[#24242399] bg-lightGray3"
              id="birthday"
              {...register("data.date_of_birth")}
            />
            {errors.data?.date_of_birth && (
              <p className="text-red-500">Este campo es necesario</p>
            )}
          </div>
          <label className="mb-1 font-low text-sm text-[#242423]">Sexo</label>
          <span className="flex items-center gap-4 col-span-2">
            <fieldset>
              <div className="flex items-center gap-3">
                <input
                  name="genre-0"
                  type="radio"
                  id="contactChoice1-0"
                  value="F"
                  {...register("data.gender")}
                />
                <label htmlFor="contactChoice1-0">Femenino</label>
                <input
                  name="genre-0"
                  type="radio"
                  id="contactChoice2-0"
                  value="M"
                  {...register("data.gender")}
                />
                <label htmlFor="contactChoice2-0">Masculino</label>
              </div>
            </fieldset>
            {errors.data?.gender && (
              <p className="text-red-500">Este campo es necesario</p>
            )}
          </span>
          <label
            htmlFor="email"
            className="mb-1 font-low text-sm text-[#242423]"
          >
            Email
          </label>
          <div className="col-span-2 relative">
            <section className="relative buscador-centro-ubicacion-input-div">
              <input
                className="rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3 w-full"
                type="email"
                name="email"
                {...register("data.email_address")}
              />
              <section className="absolute z-50 bg-white max-h-[220px] overflow-y-auto overflow-x-hidden top-12 rounded-md shadow-xl w-[300px]">
                <div className="flex flex-col gap-1"></div>
              </section>
            </section>
            {errors.data?.email_address && (
              <p className="text-red-500">Este campo es necesario</p>
            )}
          </div>

          <label className="mb-1 font-low text-sm text-[#242423]">
            Tipo y número de documento{" "}
          </label>
          <span className="flex flex-col col-span-2">
            <div className="flex gap-4">
              <select
                name="docType"
                className="px-2 py-1 text-xs h-8 bg-lightGray3 rounded-md"
                {...register("documentation.doc_type")}
              >
                <option className="text-xs" value="IDENTITY_CARD">
                  DNI
                </option>
                <option className="text-xs" value="PASSPORT">
                  Pasaporte
                </option>
              </select>
              <label className="mb-1 flex flex-col gap-1 text-xs text-[#242423] w-full ">
                <div className="relative w-full">
                  <input
                    name="docNumber"
                    className="w-full rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
                    placeholder="Número de documento"
                    {...register("documentation.number")}
                  />
                </div>
              </label>
            </div>
            {errors.documentation?.number && (
              <p className="text-red-500">Este campo es necesario</p>
            )}
          </span>

          <label
            htmlFor="placeofbirth"
            className="mb-1 font-low text-sm text-[#242423]"
          >
            Lugar de Nacimiento
          </label>
          <div className="col-span-2 relative">
            <section className="relative buscador-centro-ubicacion-input-div">
              <input
                className="rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3 w-full"
                type="text"
                name="placeofbirth"
                {...register("documentation.birth_place")}
              />
              <section className="absolute z-50 bg-white max-h-[220px] overflow-y-auto overflow-x-hidden top-12 rounded-md shadow-xl w-[300px]">
                <div className="flex flex-col gap-1"></div>
              </section>
            </section>
            {errors.documentation?.birth_place && (
              <p className="text-red-500">Este campo es necesario</p>
            )}
          </div>

          <label
            htmlFor="issuanceLocation"
            className="mb-1 font-low text-sm text-[#242423]"
          >
            Lugar de emision del documento de viaje
          </label>
          <div className="col-span-2 relative">
            <section className="relative buscador-centro-ubicacion-input-div">
              <input
                className="rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3 w-full"
                type="text"
                name="issuanceLocation"
                {...register("documentation.issuance_location")}
              />
              <section className="absolute z-50 bg-white max-h-[220px] overflow-y-auto overflow-x-hidden top-12 rounded-md shadow-xl w-[300px]">
                <div className="flex flex-col gap-1"></div>
              </section>
            </section>
            {errors.documentation?.issuance_location && (
              <p className="text-red-500">Este campo es necesario</p>
            )}
          </div>

          <label
            className="mb-1 font-low text-sm text-[#242423]"
            htmlFor="issuanceDate"
          >
            Fecha de emision del documento de viaje
          </label>
          <div className="col-span-2 relative">
            <input
              name="issuanceDate"
              type="date"
              className="w-full rounded-md px-4 py-2 text-xs text-black focus:outline-none placeholder:text-[#24242399] bg-lightGray3"
              id="birthday"
              {...register("documentation.issuance_date")}
            />
            {errors.documentation?.issuance_date && (
              <p className="text-red-500">Este campo es necesario</p>
            )}
          </div>
          <label
            className="mb-1 font-low text-sm text-[#242423]"
            htmlFor="expiryDate"
          >
            Fecha de venicimiento del documento de viaje
          </label>
          <div className="col-span-2 relative">
            <input
              name="expiryDate"
              type="date"
              className="w-full rounded-md px-4 py-2 text-xs text-black focus:outline-none placeholder:text-[#24242399] bg-lightGray3"
              id="expiryDate"
              {...register("documentation.expiry_date")}
            />
            {errors.documentation?.expiry_date && (
              <p className="text-red-500">Este campo es necesario</p>
            )}
          </div>
          <label
            htmlFor="issuanceCounrty"
            className="mb-1 font-low text-sm text-[#242423]"
          >
            Pais de Emision del documento de viaje
          </label>
          <div className="col-span-2 relative">
            <input
              name="issuanceCounrty"
              className="w-full rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
              id="issuanceCounrty"
              {...register("documentation.issuance_country")}
            />
            {errors.documentation?.issuance_country && (
              <p className="text-red-500">Este campo es necesario</p>
            )}
          </div>
          <label
            htmlFor="nationality"
            className="mb-1 font-low text-sm text-[#242423]"
          >
            Nacionalidad
          </label>
          <div className="col-span-2 relative">
            <input
              name="nationality"
              className="w-full rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
              id="nationality"
              {...register("documentation.nationality")}
            />
            {errors.documentation?.nationality && (
              <p className="text-red-500">Este campo es necesario</p>
            )}
          </div>
          <label className="mb-1 font-low text-sm text-[#242423]">
            Tipo y número de telefono{" "}
          </label>
          <span className="flex flex-col col-span-2 gap-4">
            <div className="flex gap-4">
              <select
                name="docType"
                className="px-2 py-1 text-xs h-8 bg-lightGray3 rounded-md"
                {...register("contact_phones[0].device_type")}
              >
                <option className="text-xs" value="CEL">
                  CEL
                </option>
                <option className="text-xs" value="TEL">
                  TEL
                </option>
              </select>
              <label className="mb-1 flex flex-col gap-1 text-xs text-[#242423] w-full ">
                <div className="relative w-full flex gap-2">
                  <input
                    name="docNumber"
                    className="w-4/12 rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
                    placeholder="+XX"
                    {...register("contact_phones[0].country_calling_code")}
                  />
                  <input
                    name="docNumber"
                    className="w-8/12 rounded-md px-4 py-2 text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
                    placeholder="XXX XXX XXXX"
                    {...register("contact_phones[0].number")}
                  />
                </div>
              </label>
            </div>

            {errors.contact_phones?.[0]?.number &&
              errors.contact_phones?.[0]?.country_calling_code && (
                <p className="text-red-500">Este campo es necesario</p>
              )}
          </span>
        </div>
        <div className="flex flex-col gap-5">
          <div className="border border-gray2 rounded-lg overflow-hidden min-w-max p-4 flex flex-col">
            <h1 className=" font-bold text-3xl">Display de reserva</h1>
            <h2 className=" text-2xl">{HotelInfo[0].Name?.[0]}</h2>
            <div className="grid grid-cols-2 ">
              {
                // TODO add support for multiple people types
              }
              <div className="grid-rows-4">
                <h2>Personas</h2>
                <h2>Tipo de habitacion</h2>
                <h3>Fechas de entrada</h3>
                <h3>Fechas de salida</h3>
              </div>
              <div className="grid-rows-4 text-right">
                <p>{Number(Adults) + Number(Children)}</p>
            {/* <p>{RoomCategory[0]._ || "-"}</p> */}
                {/* <p>{format(new Date(checkInDay), "dd/MM/yyyy")}</p>
                <p>{format(new Date(checkOutDay), "dd/MM/yyyy")}</p> */}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div className="border border-gray2 rounded-lg overflow-hidden min-w-max p-4 flex flex-col">
              <h1 className="font-bold text-3xl">Costos</h1>
              <div className="grid grid-cols-2 items-center">
                <div className="grid-rows-2">
                  <h2 className="text-2xl">Precio total</h2>
                </div>
                <div className="grid-rows-2 text-right text-xl">
                  <span>{TotalFixAmounts?.[0].$.Gross}</span>{" "}
                  <span>{$.Currency}</span>
                </div>
              </div>
            </div>
          </div>
          <button
            onSubmit={handleSubmit(onSubmit)}
            className="relative col-span-2 row-span-2 text-darkBlue text-center py-4 border-2 border-darkBlue text-2xl font-semibold w-full rounded-md mb-2 cursor-pointer hover:bg-darkBlue hover:text-white active:opacity-75 transition-all justify-self-end self-end"
          >
            RESERVAR
          </button>
        </div>
      </form>
    </main>
  );
}
