import React from "react";
import arrow from "../../iconos/arrow_origen.png";
import destino from "../../iconos/destino_icon.png";
import passenger from "../../iconos/passenger.png";
import { useState } from "react";
import { useLocationQuery } from "../../hooks/useLocationQuery";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { AutoComplete } from "../../utils/AutoComplete";
import { useLocation, useParams } from "react-router-dom";
import { z } from "zod";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { zodResolver } from "@hookform/resolvers/zod";
import { format, isBefore } from "date-fns";
import NewCalendar from "../../utils/NewCalendar";

export const HotelSearch = () => {
  const { parsedHash, changeQuery } = useLocationQuery();
  const location = useLocation();
  const [opcionrutas, setOpcionRutas] = useState(
    parsedHash.opcionrutas || "idayvuelta"
  );
  const { tour_op_id } = useParams();
  const type = location.pathname.includes("search-flight")
    ? "Flights"
    : "Packages";

  const [rangeDate, setRangeDate] = useState();
  const [openFsalida, toggleSalida] = useToggleVisibility();

  const navegar = (data) => {
    changeQuery({
      query: data,
      route: location.pathname.includes("search-flight")
        ? `${tour_op_id}/search-flight`
        : `${tour_op_id}/search-packages`,
      go: true,
    });
  };

  const schema = z.object({
    originLocationCode:
      type === "Flights"
        ? z
            .string({ required_error: "Campo requerido" })
            .min(3, { message: "Mínimo 3 caracteres" })
        : z.string().optional(),
    destinationLocationCode: z
      .string({ required_error: "Campo requerido" })
      .min(3, { message: "Mínimo 3 caracteres" }),
    departureDate: z.string({ required_error: "Campo requerido" }).min(1),
    returnDate:
      opcionrutas === "idayvuelta"
        ? z.string({ required_error: "Campo requerido" }).min(1)
        : z.string().optional(),
    adults: z
      .string({
        required_error: "Campo requerido",
      })
      .regex(/^\+?([1-9]\d*)$/, { message: "Número positivo" }),
    bebes: z.string().optional(),
    menores: z.string().optional(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      departureDate: parsedHash?.departureDate,
      returnDate: parsedHash?.returnDate,
      originLocationCode: parsedHash?.originLocationCode,
      destinationLocationCode: parsedHash?.destinationLocationCode,
      adults: parsedHash?.adults || "1",
    },
  });

  const [oricodeInput, setOricodeInput] = useState(
    parsedHash.originLocationCode ?? ""
  );
  const [descodeInput, setDescodeInput] = useState(
    parsedHash.destinationLocationCode ?? ""
  );

  const onSubmit = (data) => {
    navegar({
      ...data,
      // ...{ opcionrutas: opcionrutas },
    });
  };

  // const disableVuelta = (date) =>
  //   isBefore(date, new Date(watch("departureDate") + "T00:00:00"));

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg pr-4 pb-4 w-[230px] max-h-[470px] "
    >
      <span className="flex flex-col gap-1 pb-[26px] leading-0">
        <h2 className="text-[#242423] whitespace-nowrap text-xl font-[700] pl-2 m-0">
          Busca tu hospedaje
        </h2>
        <h3 className="pl-2 m-0">A donde queres ir?</h3>
      </span>
      {/* Form */}
      <div className="flex flex-col gap-3">
        {/* Input for location searched */}
        <div className="relative border border-[#8D99AE] flex gap-4 px-2 py-2 rounded-lg items-center">
          <div className="flex items-center justify-center rounded-md px-4 py-3 bg-lightBlue/80">
            <img className="w-4" src={destino} alt="destino" />
          </div>
          <AutoComplete
            justCities={type === "Packages" ? true : false}
            defaultValue={parsedHash.destinationLocationCode}
            name="destinationLocationCode"
            placeholder="Destino"
            handleAction={(d) =>
              setValue("destinationLocationCode", d.toUpperCase())
            }
            register={register}
            classname="text-[#24242380] text-sm bg-inherit w-full outline-none"
            notIcon={true}
            inputState={[descodeInput, setDescodeInput]}
          />
          <div className="absolute text-red-600 text-xs bottom-0.5 left-16 whitespace-nowrap">
            <ErrorMessage
              errors={errors}
              name="destinationLocationCode"
              as="p"
            />
          </div>
        </div>
        {/* Input for dates needed */}
        <div className="relative border border-[#8D99AE] rounded-lg justify-center flex items-center">
          <div className="py-10 text-center text-[#24242380] rounded-tl-xl rounded-bl-xl">
            <div className="flex flex-col h-5 items-start gap-2">
              <input
                readOnly
                name="departureDate"
                placeholder="Ida"
                onClick={() => toggleSalida()}
                value={
                  !watch("departureDate")
                    ? parsedHash?.departureDate
                      ? format(
                          new Date(parsedHash?.departureDate + "T00:00:00"),
                          "dd/MM/yyyy"
                        )
                      : "Ida"
                    : format(
                        new Date(watch("departureDate") + "T00:00:00"),
                        "dd/MM/yyyy"
                      )
                }
                className="flex items-center justify-center w-full px-2 text-sm text-center outline-none  bg-inherit h-7"
              />
              {openFsalida ? (
                <NewCalendar
                  handlers={[
                    rangeDate,
                    (date) => {
                      setRangeDate(date);
                      setValue(
                        "departureDate",
                        date?.from ? format(date?.from, "yyyy-MM-dd") : ""
                      );
                      setValue(
                        "returnDate",
                        date?.to ? format(date?.to, "yyyy-MM-dd") : ""
                      );
                      date?.from && date?.to && toggleSalida();
                    },
                  ]}
                  mode={"range"}
                  numberOfMonths={2}
                  close={toggleSalida}
                />
              ) : null}
            </div>
            <div className="absolute text-red-600 text-xs bottom-0.5 left-2 whitespace-nowrap">
              <ErrorMessage errors={errors} name="departureDate" as="p" />
            </div>
          </div>
          <div className="w-[1px] h-2 bg-[#DAE0E6] py-8" />
          <div className="py-10 text-center text-[#24242380] rounded-tr-xl rounded-br-xl">
            <div className="flex items-center h-5 gap-3">
              <input
                readOnly
                name="returnDate"
                placeholder="Vuelta"
                onClick={() => toggleSalida()}
                value={
                  !watch("returnDate")
                    ? parsedHash?.returnDate
                      ? format(
                          new Date(parsedHash?.returnDate + "T00:00:00"),
                          "dd/MM/yyyy"
                        )
                      : "Vuelta"
                    : format(
                        new Date(watch("returnDate") + "T00:00:00"),
                        "dd/MM/yyyy"
                      )
                }
                className="flex items-center justify-center w-full px-2 text-sm text-center outline-none bg-inherit h-7"
              />
            </div>
            <div className="absolute text-red-600 text-xs bottom-0.5 right-2 whitespace-nowrap">
              <ErrorMessage errors={errors} name="returnDate" as="p" />
            </div>
          </div>
        </div>

        <div className="relative border border-[#8D99AE] flex gap-4 px-2 py-2 rounded-lg items-center">
          <div className="flex items-center justify-center rounded-md px-4 py-3 bg-lightBlue/80">
            <img className="w-5" src={passenger} alt="pasajeros" />
          </div>
          <input
            name="adults"
            {...register("adults")}
            className="text-[#24242380] text-xs bg-inherit w-full outline-none"
            placeholder="PASAJEROS"
          />
          <div className="absolute text-red-600 text-xs bottom-0.5 left-16 whitespace-nowrap">
            <ErrorMessage errors={errors} name="adults" as="p" />
          </div>
        </div>
        <button
          type="submit"
          className="bg-lightBlue/80 font-medium rounded-lg mt-1 px-4 py-3 text-center shadow-[0px 4px 4px rgba(0, 0, 0, 0.25)] cursor-pointer hover:opacity-80"
        >
          Buscar
        </button>
      </div>
    </form>
  );
};
