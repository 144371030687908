import React from "react";

export default function HotelCardImage({ image }) {
  return (
    <div className="mb:w-full lg:w-[20%] mb:height:1/4 lg:h-full flex-shrink-0 ">
      <img
        src={image}
        alt="Hotel"
        className="w-full h-full object-cover rounded-md"
      />
    </div>
  );
}
