import React from "react";

export default function HotelAdressTab({Address,Latitude,Longitude}) {
  return (
    <div className="flex flex-col gap-2">
      <p>
        <span className="font-bold">Dirección:</span>{" "}
        {Address?.[0] || "COMPLETAR"}
      </p>
      <a
        href={`https://www.google.com.ar/maps/dir//${Latitude?.[0]},${Longitude?.[0]}/@${Latitude?.[0]},${Longitude?.[0]},17z/data=!4m2!4m1!3e0?entry=ttu`}
        target="_blank"
        rel="noreferrer"
        className="button bg-darkBlue text-white text-center"
      >
        Abrir en Google Maps
      </a>
    </div>
  );
}
