import React from "react";
import PageTitle from "../../utils/PageTitle";
import TableSuppliers from "./tableSupplier/TableSuppliers";
import SEO from "../../utils/SEO";

export default function AdminPackages() {
  return (
    <>
      <div className="mt-8">
        <SEO title={"Mis Proveedores"} />
        <PageTitle
          title={"Mis Proveedores"}
          subTitle={
            " Controlá, Gestioná y Personalizá cada uno de tus proveedores."
          }
          route={"panel/suppliers/add-supplier"}
          btnText={"Agregar Proveedor"}
          buttonVisivility="True"
        />
        <TableSuppliers />
      </div>
    </>
  );
}
