import React from "react";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";

export default function EmailButton(suppEmail) {
  return (
    <a
      className="relative mx-0.5 h-7 w-7 hover:bg-[#EEE] rounded-full transition-colors group/send"
      href={`mailto:${suppEmail}`}
    >
      <MailOutlinedIcon
        sx={{
          color: "#A0AFCD",
          height: "20px",
          width: "20px",
        }}
        alt="Mail"
      />
      <span className="absolute whitespace-nowrap bg-white border bottom-full -left-20 py-0.5 px-1 rounded scale-0 transition-transform group-hover/send:scale-100">
        Envío Email
      </span>
    </a>
  );
}
