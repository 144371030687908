import "../../../../styles/buscador.css";
import HotelCitySearch from "./HotelCitySearch";
import HotelDateSelector from "./HotelDateSelector";
import HotelPassengerSelector from "./HotelPassengerSelector";

export default function HotelsSearchModules({
  useForm,
  passangerState,
  oricodeState,
  parsedHash,
}) {
  const { register, setValue, watch, errors } = useForm;

  return (
    <>
      <span className="flex gap-20 items-center">
        <p className="text-xl font-medium text-lightBlue">Hoteles</p>
      </span>

      <div className="flex gap-3 justify-between">
        {/* cityCode */}
        <div className="bg-[#EFEFEF] p-1 h-20 pt-2 rounded-2xl flex items-center gap-1 justify-between">
          <HotelCitySearch
            setValue={setValue}
            register={register}
            parsedHash={parsedHash}
            oricodeState={oricodeState}
            errors={errors}
          />
        </div>

        {/* fechas */}
        <div className="bg-[#EFEFEF] relative p-1 h-20 pt-2 rounded-2xl flex items-center gap-1 justify-between w-72">
          <HotelDateSelector
            watch={watch}
            parsedHash={parsedHash}
            setValue={setValue}
            errors={errors}
          />
        </div>

        {/* passengers */}
        <div className="relative group/passengers">
          <HotelPassengerSelector
            passangerState={passangerState}
            setValue={setValue}
            errors={errors}
          />
        </div>
        <button
          type="submit"
          className="bg-[#CEDEF1] rounded-xl py-1 px-12 text-black shadow-sm shadow-lightBlue2"
        >
          <span className="text-black font-medium">Buscar</span>
        </button>
      </div>
    </>
  );
}
