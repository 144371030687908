import React, { useEffect, useMemo, useState } from "react";

import Pagination from "../../../../utils/Pagination";
import TableQuoteHeader from "./TableQuoteHeader";
import TableQuoteBody from "./TableQuoteBody";
import TableQuoteFiltering from "./quoteFiltering/TableQuoteFiltering";

import { useQuotes } from "../../../../hooks/useQuotes";
import { saveTourOperator } from "../../../../utils/saveTourOperator";

export default function TableQuotes() {
  const { quotes, getQuotes } = useQuotes();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortCriteria, setSortCriteria] = useState({
    key: "price",
    direction: "asc",
  });
  const [searcherValue, setSearcherValue] = useState({
    textValue: "",
    monthValue: "",
  });
  const [filters, setFilters] = useState({
    radioDate: "creation",
    inputDate: "",
    inputStatus: "",
  });
  const lowerSearch = searcherValue.textValue.toLowerCase();

  useEffect(() => {
    getQuotes();
  }, [tourOP, getQuotes]);

  const filteredQuotes = quotes
    .filter((qto) => {
      if (!lowerSearch) return true;

      const { origin, destination, email, name } = qto;

      return (
        name?.toLowerCase().includes(lowerSearch) ||
        destination?.toLowerCase().includes(lowerSearch) ||
        email?.toLowerCase().includes(lowerSearch) ||
        origin?.toLowerCase().includes(lowerSearch)
      );
    })
    .filter((qto) => {
      const { creation_date, Flights, dates } = qto;

      // If no date filter is applied or radioDate is missing, skip the filter
      if (!filters.inputDate || !filters.radioDate) {
        return true;
      }

      // Parse input date to consistent YYYY-MM-DD format
      const inputDate = new Date(filters.inputDate).toISOString().split("T")[0];

      // Handle cases where Flights data is missing
      if (!Flights || !Flights[0]) {
        // If Flights data is missing, try to filter by creation date
        if (filters.radioDate === "creation" && creation_date) {
          const creationDate = new Date(creation_date)
            .toISOString()
            .split("T")[0];
          return creationDate === inputDate;
        }

        // If "dates" exists and radioDate is "departure", extract the first date
        if (filters.radioDate === "departure" && dates) {
          const firstDate = dates.split(" to ")[0]; // Get the first date from the string
          const parsedDepartureDate = new Date(firstDate)
            .toISOString()
            .split("T")[0];
          return parsedDepartureDate === inputDate;
        }

        return true; // If no other filter applies, return true
      }

      // Safely parse the Flights options to extract the departure date
      let itineraries;
      try {
        itineraries = JSON.parse(Flights[0]?.options ?? "{}").itineraries || [];
      } catch (error) {
        itineraries = [];
      }

      const departureDateRaw = itineraries?.[0]?.segments?.[0]?.departure?.at;

      // Parse the departure date to YYYY-MM-DD format
      const departureDate = departureDateRaw
        ? new Date(departureDateRaw).toISOString().split("T")[0]
        : null;

      // Apply the relevant date filter based on radioDate value
      if (filters.radioDate === "creation" && creation_date) {
        const creationDate = new Date(creation_date)
          .toISOString()
          .split("T")[0];
        return creationDate === inputDate;
      }
      if (filters.radioDate === "departure") {
        if (departureDate) {
          return departureDate === inputDate;
        }
        if (dates) {
          // Fallback to parsing the first date from "dates" if Flights data is missing
          const firstDate = dates.split(" to ")[0]; // Extract first date
          const parsedDepartureDate = new Date(firstDate)
            .toISOString()
            .split("T")[0];
          return parsedDepartureDate === inputDate;
        }
      }

      return true; // If no relevant filter applies, return true
    })
    .filter((qto) => {
      if (filters.inputStatus === "allBookings" || filters.inputStatus === "")
        return true;

      return qto.status === filters.inputStatus;
    })
    .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage);

  const sortedQuotes = useMemo(() => {
    const sorted = [...filteredQuotes]; // Make a copy of the filtered quotes

    if (sortCriteria.key) {
      sorted.sort((a, b) => {
        let aValue, bValue;

        if (sortCriteria.key === "name") {
          // Sort by name (convert to lowercase to handle case-insensitive sorting)
          aValue = a.name?.toLowerCase() || "";
          bValue = b.name?.toLowerCase() || "";
        } else if (sortCriteria.key === "date") {
          // Sort by date (assuming there's a date property in quotes)
          aValue = new Date(a.creation_date).getTime();
          bValue = new Date(b.creation_date).getTime();
        } else {
          return 0; // No valid sorting key
        }

        if (sortCriteria.direction === "asc") {
          if (typeof aValue === "string" && typeof bValue === "string") {
            return aValue.localeCompare(bValue);
          }
          return aValue > bValue ? 1 : -1; // Handle numerical and other comparisons
        } else {
          if (typeof aValue === "string" && typeof bValue === "string") {
            return bValue.localeCompare(aValue);
          }
          return aValue < bValue ? 1 : -1; // Handle numerical and other comparisons
        }
      });
    }

    return sorted;
  }, [filteredQuotes, sortCriteria]);

  const [tableHeaders] = useState([
    "#",
    "Tipo",
    "Nombre",
    "Destino",
    "Fecha de Salida",
    "Fecha Creación",
    "Usuario",
    "Estado",
    "Acción",
  ]);

  const handleRowSelect = (traveler) => {
    setSelectedRows((prevSelected) => {
      const isSelected = prevSelected.some((row) => row.id === traveler.id);
      return isSelected
        ? prevSelected.filter((row) => row.id !== traveler.id)
        : [...prevSelected, traveler];
    });
  };

  return (
    <>
      <div className="p-4 border-2 border-gray2 bg-white rounded-lg">
        <TableQuoteFiltering
          searcher={[searcherValue, setSearcherValue]}
          sortCriteria={sortCriteria}
          setSortCriteria={setSortCriteria}
          pdfData={selectedRows}
          filters={filters}
          setFilters={setFilters}
        />

        <table className="w-full border-collapse  rounded-lg overflow-hidden bg-lightBlue2 ">
          <TableQuoteHeader
            cols={tableHeaders}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            quotes={filteredQuotes}
            page={page}
          />
          <TableQuoteBody
            quotes={sortedQuotes}
            selectedRows={selectedRows}
            handleRowSelect={handleRowSelect}
          />
        </table>
      </div>
      <Pagination
        items={quotes}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
