import React, { useState } from "react";
import { PASSENGER_DICTIONARY } from "../../../../utils/dictionaries/passengerDictionary";

export default function HotelPassengerSelector({
  passangerState,
  setValue
}) {
  const [passengerCounter, setPassengerCounter] = passangerState;
  const [rooms, setRooms] = useState(1);

  // const [showOptions, setShowOptions] = useState(false);
  const { ADT, CHD, INF } = passengerCounter;
  const sumPassengers = ADT + CHD + INF;

  const handlePassenger = (passenger, register, min, op) => {
    const passengerValue = passengerCounter[passenger];
    const newValue =
      op === "+"
        ? passengerValue + 1
        : passengerValue > min
        ? passengerValue - 1
        : passengerValue;

    setValue(register, newValue.toString());

    setPassengerCounter((curr) => {
      return {
        ...curr,
        [passenger]: newValue,
      };
    });
  };

  const handleRooms = (op) => {
    const newValue = op === "+" ? rooms + 1 : rooms > 0 ? rooms - 1 : rooms;

    setValue("rooms", newValue.toString());

    setRooms(newValue);
  };

  return (
    <>
      <div className="bg-[#EFEFEF] p-1 h-20 pt-2 rounded-2xl flex flex-col items-center gap-1 justify-evenly cursor-pointer">
        <span className="flex gap-2 items-center">
          <p className="text-sm font-medium">Pasajeros y Habitaciones</p>
        </span>
        <p className="w-[120px] py-1 px-2 mx-auto text-sm focus:outline-none bg-[#CEDEF1] rounded-md">
          {sumPassengers} {sumPassengers > 1 ? " pasajeros" : "pasajero"}
          {/* {cabin === "ECO" ? "Económica" : "Ejecutiva"} */}
        </p>
      </div>
      <div className="absolute w-64 top-full mt-2 bg-[#EFEFEF] rounded-2xl p-4 flex flex-col gap-2 scale-0 group-hover/passengers:scale-100 transition-transform origin-top-left">
        <section className="flex flex-col gap-2">
          {Object.entries(passengerCounter).map((passenger) => {
            const { key, label, description, register, min } =
              PASSENGER_DICTIONARY[passenger[0]];
            return (
              <div className="flex justify-between items-center" key={key}>
                <div>
                  <h2 className="font-medium">{label}</h2>
                  <p className="text-xs text-gray">{description}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <button
                    type="button"
                    onClick={() => handlePassenger(key, register, min, "-")}
                    className="border w-7 h-7 rounded-full text-sm font-[700]"
                  >
                    -
                  </button>
                  <p className="text-xl">{passenger[1]}</p>
                  <button
                    type="button"
                    onClick={() => handlePassenger(key, register, min, "+")}
                    className="border w-7 h-7 rounded-full text-sm font-[700]"
                  >
                    +
                  </button>
                </div>
              </div>
            );
          })}
          <div className="flex justify-between items-center border-t pt-2">
            <p className="font-medium">Habitaciones</p>
            <div className="flex gap-2 items-center">
              <button
                type="button"
                onClick={() => handleRooms("-")}
                className="border w-7 h-7 rounded-full text-sm font-[700]"
              >
                -
              </button>
              <p className="text-xl">{rooms}</p>
              <button
                type="button"
                onClick={() => handleRooms("+")}
                className="border w-7 h-7 rounded-full text-sm font-[700]"
              >
                +
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
