import React, { useState } from "react";
import icono_calendar from "../../../../iconos/icono_calendar.png";
import { format } from "date-fns";
import { ErrorMessage } from "@hookform/error-message";
import { useToggleVisibility } from "../../../../hooks/useToggleVisibility";
import NewCalendar from "../../../../utils/NewCalendar";

export default function HotelDateSelector({
  watch,
  parsedHash,
  setValue,
  errors,
}) {
  const [openFsalida, toggleSalida] = useToggleVisibility();
  const [rangeDate, setRangeDate] = useState();
  return (
    <>
      <div className="flex flex-col max-w-[135px]">
        <span className="flex gap-2 items-center justify-center absolute top-2 left-6">
          <img src={icono_calendar} alt="icono_calendar" className="w-4" />
          <p>Fechas</p>
        </span>
        <div className="flex flex-col">
          <div className="flex flex-col h-5 items-start gap-2">
            <input
              readOnly
              autoComplete="off"
              name="departureDate"
              placeholder="Ida"
              onClick={() => toggleSalida()}
              value={
                !watch("departureDate")
                  ? parsedHash?.departureDate
                    ? format(
                        new Date(parsedHash?.departureDate + "T00:00:00"),
                        "dd/MM/yyyy"
                      )
                    : "Ida"
                  : format(
                      new Date(watch("departureDate") + "T00:00:00"),
                      "dd/MM/yyyy"
                    )
              }
              className="text-center outline-none px-2 pt-4 text-sm flex items-center justify-center w-full bg-inherit h-7"
            />
            {openFsalida ? (
              <NewCalendar
                handlers={[
                  rangeDate,
                  (date) => {
                    setRangeDate(date);
                    setValue(
                      "departureDate",
                      date?.from ? format(date?.from, "yyyy-MM-dd") : ""
                    );
                    setValue(
                      "returnDate",
                      date?.to ? format(date?.to, "yyyy-MM-dd") : ""
                    );
                    date?.from && date?.to && toggleSalida();
                  },
                ]}
                mode={"range"}
                numberOfMonths={2}
                close={toggleSalida}
              />
            ) : null}
          </div>
          <div className="absolute text-red-600 text-xs bottom-0.5 left-8 whitespace-nowrap">
            <ErrorMessage errors={errors} name="returnDate" as="p" />
          </div>
        </div>
      </div>
      <div className="h-[80%] w-[1px] bg-black" />

      <div className="flex flex-col">
        <div className="flex flex-col h-5 items-start gap-2">
          <input
            readOnly
            name="returnDate"
            placeholder="Vuelta"
            onClick={() => toggleSalida()}
            value={
              !watch("returnDate")
                ? parsedHash?.returnDate
                  ? format(
                      new Date(parsedHash?.returnDate + "T00:00:00"),
                      "dd/MM/yyyy"
                    )
                  : "Vuelta"
                : format(
                    new Date(watch("returnDate") + "T00:00:00"),
                    "dd/MM/yyyy"
                  )
            }
            className="text-center outline-none px-2 text-sm pt-4 flex items-center justify-center w-full bg-inherit h-7"
          />
        </div>
        <div className="absolute text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
          <ErrorMessage errors={errors} name="departureDate" as="p" />
        </div>
      </div>
    </>
  );
}
