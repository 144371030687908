import React, { useState } from "react";
import SEO from "../../../utils/SEO";
import HotelsSearchModules from "./searchModules/HotelsSearchModules";
import { useForm } from "react-hook-form";
import { useLocationQuery } from "../../../hooks/useLocationQuery";
import { useParams } from "react-router-dom";
import { hotelSearchSchema } from "../../../schemas/searchEngine";
import { zodResolver } from "@hookform/resolvers/zod";

export default function HotelsSearchEngine() {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(hotelSearchSchema),
  });

  const { parsedHash, changeQuery } = useLocationQuery();
  const [oricodeInput, setOricodeInput] = useState("");
  const [descodeInput, setDescodeInput] = useState("");
  const [passengerCounter, setPassengerCounter] = useState({
    ADT: 1,
    CHD: 0,
    INF: 0,
  });

  const { tour_op_id } = useParams();
  const onSubmit = (data) => {
    changeQuery({
      query: data,
      route: `${tour_op_id}/search-hotels`,
      go: true,
    });
  };
  return (
    <>
      <SEO title={"Hoteles"} />
      <form
        onSubmit={handleSubmit((data) => onSubmit(data))}
        className="buscador select-none px-8 pb-6 pt-4 bg-black mx-auto max-w-[1050px] flex flex-col gap-6 rounded-3xl "
      >
        <HotelsSearchModules
          useForm={{ register, setValue, watch, errors,clearErrors }}
          descodeState={[descodeInput, setDescodeInput]}
          oricodeState={[oricodeInput, setOricodeInput]}
          passangerState={[passengerCounter, setPassengerCounter]}
          parsedHash={parsedHash}
        />
      </form>
    </>
  );
}
