import React from "react";

export default function SelectQuoteStatus({ status }) {
  const statusOptions = [
    { value: "to-start", label: "Para Iniciar", color: "#FFDD57" },
    { value: "quote-asked", label: "Pedido Coti", color: "#FFABAB" },
    { value: "info-missing", label: "Falta Info", color: "#FF61A6" },
    { value: "no-answer", label: "Sin Respuesta", color: "#FF6F91" },
    { value: "ask-info", label: "Pedir Datos", color: "#6A0572" },
    { value: "quote-sent", label: "Coti Enviada", color: "#57A0FF" },
    { value: "book", label: "Confirmado", color: "#ABFFB0" },
  ];
  // const [currentStatus, setCurrentStatus] = React.useState('quote-sent'); 

  return (
    <>
      <select  defaultValue={status} className="border-2 border-gray2 rounded-lg ">
        <option disabled value="">-- Seleccionar Estado --</option>
        {statusOptions.map(({ value, label, color }) => (
          <option key={value} value={value} style={{ color }}>
            {label}
          </option>
        ))}
      </select>
    </>
  );
}
