import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import walltrip_logo from "../../iconos/logos/walltrip.png";
import mini_walltrip_logo from "../../iconos/mini_walltrip_logo.png";
// import config from "../../iconos/user.png";
import { sidebarOptions } from "./sidebarOptions";
import clsx from "clsx";
import { saveTourOperator } from "../../utils/saveTourOperator";
import SidebarPanelSearcher from "./SidebarPanelSearcher";
// import useAgencies from "../../hooks/useAgencies";
import { useEffect } from "react";
import MenuLink from "./MenuLink";
import UserPanel from "./UserPanel";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { MdMenu } from "react-icons/md";
export default function SidebarPanelMobile() {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { tour_op_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const userData = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData")
  );
  // const { agencies, getAgenciesByTourOperator } = useAgencies();

  useEffect(() => {
    // DESCOMENTAR EL DÍA QUE SE IMPLEMENTE EL LOGO DE AGENCIA
    // getAgenciesByTourOperator();

    // REDIRECCIÓN A 'LOGIN' EN CASO DE NO TENES SESIÓN EN SUPERADMIN
    if (!userData) {
      navigate(`/${tour_op_id}/login`);
      return;
    }

    // REDIRECCIÓN A HOME EN CASO DE ROL NO PERMITIDO EN SUPERADMIN
    if (userData?.role_id === 3) {
      navigate(`/${tour_op_id}/flights`);
      return;
    }
  }, [userData, tour_op_id, navigate]);

  return (
    <>
      <button
        className={`absolute bg-white border-2 rounded-lg  z-panel transition-all duration-500 ease-in-out  ${
          isVisible ? "hidden" : "right-3 top-2"
        }`}
        onClick={(e) => toggleVisibility()}
      >
        <MdMenu size={30} />
      </button>
      <aside
        className={clsx(
          "fixed flex flex-col transition-[width] duration-500 ease-in-out h-[100vh] mr-3 rounded-r-[25px] py-4  bg-white  scrollbar-hide z-custom overflow-y-scroll z-custom overflow-x: visible", //z-custom overflow-y-scroll
          {
            "w-[0px]": !isVisible,
          },
          {
            "w-[237px] px-4": isVisible,
          }
        )}
      >
        <Link to={`/${tour_op_id || "walltrip"}/flights`}>
          <div>
            {isVisible ? (
              <img
                src={walltrip_logo}
                alt="walltrip"
                className="w-37 h-11 mx-auto"
              />
            ) : (
              <img
                src={mini_walltrip_logo}
                alt="walltrip"
                className="w-13 h-11 mx-auto"
              />
            )}
          </div>
        </Link>

        <section className="flex h-full flex-col justify-between">
          {/* options links */}
          <ul className="flex flex-col gap-4 my-8 ">
            <SidebarPanelSearcher isHovered={isVisible} />
            {sidebarOptions.map((option, index) => (
              <li className="relative " key={index}>
                {location.pathname.includes(option.link) ? (
                  <div className="absolute -left-4 bg-[#242423] h-6 w-[5px] rounded-r-[6px]" />
                ) : null}
                <div onClick={(e) => toggleVisibility()}>
                  <MenuLink
                    label={option.label}
                    icon={option.icon}
                    link={option.link}
                    location={location}
                    option={option}
                    isHovered={isVisible}
                  />
                </div>
              </li>
            ))}
          </ul>

          <div className="z-panel">
            <UserPanel
              tour_op_id={tour_op_id}
              location={location}
              userData={userData}
              tourOP={tourOP}
              isHovered={isVisible}
            />
          </div>
        </section>
      </aside>
    </>
  );
}
