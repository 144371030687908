import SEO from "../../utils/SEO";
import Head from "../head";
import Footer from "../home/footer/Footer";
import HotelAdmin from "./hotelsListing/HotelAdmin";
export default function Hotels() {
  return (
    <main className="font-body">
      <SEO title={"Hoteles"} />
      <Head />
      <HotelAdmin />
      <Footer />
    </main>
  );
}
