import { z } from "zod";

export const quoteSchema = z.object({
  name: z.string().optional(),
  origin: z.string().optional(),
  destination: z.string().optional(),
  email: z.string().optional(),
  whatsapp: z
    .string()
    .optional()
    .refine((value) => !value || /^\+[0-9]{10,}$/.test(value), {
      message:
        "Número de WhatsApp inválido. Debe comenzar con '+' y tener al menos 10 números.",
    }),
  dates: z.string().optional(),
  ask: z.string().optional(),
  type: z.string().min(1, { message: "Campo incompleto" }),
  passengers: z.string().optional(),
  channel: z.string().default("manual"),
});
