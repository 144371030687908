import { CallEndOutlined } from "@mui/icons-material";
import React from "react";

export default function CallButton({ number }) {
  return (
    <a
      href={`tel:${number}`}
      className="relative h-[24px] w-[24px] flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors cursor-pointer group/call"
    >
      <CallEndOutlined
        sx={{
          color: "#A0AFCD",
          height: "18px",
          width: "18px",
        }}
        alt="call"
      />
      <span className="absolute whitespace-nowrap bg-white border -top-8 -left-10 transform -translate-x-1/2 py-0.5 px-1 rounded scale-0 transition-transform group-hover/call:scale-100">
        Llamar Pasajero
      </span>
    </a>
  );
}
