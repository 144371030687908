
import CalculateSharpIcon from '../../iconos/calculate.svg';
import dashboard_customize from '../../iconos/dashboard_customize.svg';
import library_add_check from '../../iconos/library_add_check.svg';
import luggage from '../../iconos/luggage.svg';
import group from '../../iconos/group.svg';
import account_balance from '../../iconos/account_balance.svg';
import proveedores from "../../iconos/transport.png";

export const sidebarOptions = [
  {
    link: "sales/quotes/create-quote",
    label: "Cotizador",
    icon: CalculateSharpIcon,
  },
  {
    link: "sales/quotes/my-quotes/flight",
    label: "Mis Cotizaciones",
    icon: dashboard_customize,
  },
  {
    link: "sales/bookings/package",
    label: "Reservas",
    icon: library_add_check,
  },
  {
    link: "packages/my-packages",
    label: "Paquetes",
    icon: luggage,
    children: [
      {
        link: "packages/my-packages",
        label: "Mis paquetes",
      },
      {
        link: "packages/add-package",
        label: "Agregar paquete",
      },
    ],
  },
  // {
  //   link: "activities/my-activities",
  //   label: "Actividades",
  //   icon: actividades,
  //   children: [
  //     {
  //       link: "activities/my-activities",
  //       label: "Mis Actividades",
  //     },
  //     {
  //       link: "activities/add-activity",
  //       label: "Agregar actividad",
  //     },
  //   ],
  // },
  // {
  //   link: "proveedores",
  //   label: "Proveedores",
  //   icon: proveedores,
  // },
  // {
  //   link: "crm",
  //   label: "CRM",
  //   icon: crm,
  // },
  {
    link: "suppliers/my-suppliers",
    label: "Proveedores",
    icon: proveedores,
    children: [
      {
        link: "suppliers/my-suppliers",
        label: "Mis Proveedores",
      },
      {
        link: "suppliers/add-supplier",
        label: "Agregar Proveedores",
      }
    ],
  },
  {
    link: "invoicing/my-invoices",
    label: "Facturación",
    icon: account_balance,
    children: [
      {
        link: "invoicing/my-invoices",
        label: "Mis Facturaciones",
      },
      {
        link: "invoicing/add-payment",
        label: "Agregar Pago",
      },
    ],
  },
  {
    link: "travelers",
    label: "Pasajeros",
    icon: group,
  },
];
