export const mocksHotel = [
  {
    $: { Code: "1asd" },
    image:"https://plus.unsplash.com/premium_photo-1661964071015-d97428970584?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aG90ZWx8ZW58MHx8MHx8fDA%3D",
    HotelInfo: [
      {
        Description:["This Hotel is a test"],
        Name: "Grand Hyatt",
        HotelCategory: [{ _: "5" }],
        Address:["El gran Budapezs"],
        Latitude:[40.748817],
        Longitude:[-73.985428],
        TimeInformation: [
          {
            CheckTime: [
              {
                $: {
                  CheckIn: "2024-03-15",
                  CheckOut: "2024-03-18",
                },
              },
            ],
          },
        ],
      },
    ],
    HotelOptions: [
      {
        HotelOption: [
          {
            Prices: [
              {
                Price: [
                  {
                    $: {
                      TotalFixAmounts: "1500",
                    },
                  },
                ],
              },
            ],
            HotelRooms: [
              {
                HotelRoom: [
                  {
                    Name:"sdads",
                    RoomCategory:[],
                    RoomOccupancy:[{$:"adsd"}],
                    RoomType: "King Suite",
                    RoomCapacity: 2,
                    Amenities: ["Wifi", "Breakfast", "Pool Access"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    $: { Code: "2asd" },
    image:"https://assets.tivolihotels.com/image/upload/q_auto,f_auto,c_limit,w_1378/media/minor/tivoli/images/brand_level/footer/1920x1000/thr_aboutus1_1920x1000.jpg",
    HotelInfo: [
      {
        Description: ["A luxurious stay in the heart of the city"],
        Name: "Hilton Downtown",
        HotelCategory: [{ _: "4" }],
        Address: ["123 City Center, New York"],
        Latitude: [40.730610],
        Longitude: [-73.935242],
        TimeInformation: [
          {
            CheckTime: [
              {
                $: {
                  CheckIn: "2024-04-01",
                  CheckOut: "2024-04-05",
                },
              },
            ],
          },
        ],
      },
    ],
    HotelOptions: [
      {
        HotelOption: [
          {
            Prices: [
              {
                Price: [
                  {
                    $: {
                      TotalFixAmounts: "1800",
                    },
                  },
                ],
              },
            ],
            HotelRooms: [
              {
                HotelRoom: [
                  {
                    Name: "Ocean View Suite",
                    RoomCategory: [],
                    RoomOccupancy: [{ $: "2 Adults" }],
                    RoomType: "Luxury Suite",
                    RoomCapacity: 3,
                    Amenities: ["Wifi", "Breakfast", "Sea View"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    $: { Code: "3asd" },
    image:"https://www.usatoday.com/gcdn/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg",
    HotelInfo: [
      {
        Description: ["Modern hotel with excellent services"],
        Name: "Radisson Blu",
        HotelCategory: [{ _: "4" }],
        Address: ["456 Beachside Ave, Miami"],
        Latitude: [25.761680],
        Longitude: [-80.191790],
        TimeInformation: [
          {
            CheckTime: [
              {
                $: {
                  CheckIn: "2024-05-01",
                  CheckOut: "2024-05-04",
                },
              },
            ],
          },
        ],
      },
    ],
    HotelOptions: [
      {
        HotelOption: [
          {
            Prices: [
              {
                Price: [
                  {
                    $: {
                      TotalFixAmounts: "2000",
                    },
                  },
                ],
              },
            ],
            HotelRooms: [
              {
                HotelRoom: [
                  {
                    Name: "Standard Room",
                    RoomCategory: [],
                    RoomOccupancy: [{ $: "1 Adult" }],
                    RoomType: "Standard",
                    RoomCapacity: 2,
                    Amenities: ["Wifi", "Air Conditioning", "Pool Access"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    $: { Code: "4asd" },
    image:"https://cdn.loewshotels.com/loewshotels.com-2466770763/cms/imagepool/66a97065eb7bf.jpg",
    HotelInfo: [
      {
        Description: ["Luxury hotel with a spa and wellness center"],
        Name: "Four Seasons",
        HotelCategory: [{ _: "5" }],
        Address: ["789 Park Ave, Los Angeles"],
        Latitude: [34.052235],
        Longitude: [-118.243683],
        TimeInformation: [
          {
            CheckTime: [
              {
                $: {
                  CheckIn: "2024-06-01",
                  CheckOut: "2024-06-10",
                },
              },
            ],
          },
        ],
      },
    ],
    HotelOptions: [
      {
        HotelOption: [
          {
            Prices: [
              {
                Price: [
                  {
                    $: {
                      TotalFixAmounts: "3000",
                    },
                  },
                ],
              },
            ],
            HotelRooms: [
              {
                HotelRoom: [
                  {
                    Name: "Presidential Suite",
                    RoomCategory: [],
                    RoomOccupancy: [{ $: "2 Adults, 2 Kids" }],
                    RoomType: "Suite",
                    RoomCapacity: 4,
                    Amenities: ["Wifi", "Breakfast", "Private Pool"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    $: { Code: "5asd" },
    image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEVl6p3r_zLZmpaBI1SxOAX6DzEH9txtuQFQ&s",
    HotelInfo: [
      {
        Description: ["Relaxing resort with ocean views"],
        Name: "Marriott Resort",
        HotelCategory: [{ _: "4" }],
        Address: ["321 Ocean Drive, Miami"],
        Latitude: [25.761680],
        Longitude: [-80.191790],
        TimeInformation: [
          {
            CheckTime: [
              {
                $: {
                  CheckIn: "2024-07-01",
                  CheckOut: "2024-07-05",
                },
              },
            ],
          },
        ],
      },
    ],
    HotelOptions: [
      {
        HotelOption: [
          {
            Prices: [
              {
                Price: [
                  {
                    $: {
                      TotalFixAmounts: "2200",
                    },
                  },
                ],
              },
            ],
            HotelRooms: [
              {
                HotelRoom: [
                  {
                    Name: "Beachfront Room",
                    RoomCategory: [],
                    RoomOccupancy: [{ $: "2 Adults" }],
                    RoomType: "Beachfront",
                    RoomCapacity: 2,
                    Amenities: ["Wifi", "Ocean View", "Balcony"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  
];
