import React from "react";

export default function HotelCardBody({
  Name,
  stars,
  TotalFixAmounts,
  HotelRooms,
  CheckIn,
  CheckOut,
  toggleVisibility,
  isVisible,
  $
}) {
  return (
    <div className="flex flex-col gap-2 w-full grow justify-between">
      <header className="flex items-center">
        <h1 className=" text-xl mr-2">{Name?.[0]}</h1>
        <div>
          {stars.map((star, index) => (
            <span key={index}>{star}</span>
          ))}
        </div>
        <p className="ml-auto">
          Desde <span>{TotalFixAmounts?.[0].$.Gross}</span>{" "}
          <span>{$.Currency}</span>
        </p>
      </header>

      <h4>{HotelRooms?.[0].HotelRoom?.[0]?.RoomCategory?.[0]?._ || ""}</h4>
      <p>Check-In desde {CheckIn}hs</p>
      <p>Check-Out hasta {CheckOut}hs</p>
      <button onClick={toggleVisibility} className="button outline w-full">
        {`${isVisible ? "-" : "+"} info`}
      </button>
    </div>
  );
}
