import React, { useEffect } from "react";
import { HotelSearch } from "../HotelSearch";
import HotelListing from "./HotelListing";
import { useGetHotels } from "../../../hooks/useGetHotels";
import Anicarga from "../../anicarga";
import { useLocationQuery } from "../../../hooks/useLocationQuery";
import { mocksHotel } from "../../../mocks/mocksHotelJunniper";

export default function HotelAdmin() {
  const { getJuniperHotels2, loading } = useGetHotels();
  const { parsedHash } = useLocationQuery();
  const adults = parseInt(parsedHash.adults || "0", 10);
  const children = parseInt(parsedHash.children || "0", 10);
  const infants = parseInt(parsedHash.infants || "0", 10);

  // Load pax with passengers 
  const pax = [
    ...Array(adults).fill(30), // Add 30 for each adult
    ...Array(children).fill(12), // Add 12 for each child
    ...Array(infants).fill(1), // Add 1 for each infant
  ];
  const queryHotel = `&Pax=${JSON.stringify(pax)}&City="${
    parsedHash.cityName
  }"&StartDate="${parsedHash.departureDate}"&EndDate="${
    parsedHash.returnDate
  }"&CountryOfResidence="AR"&ShowHotelInfo=true`;

  useEffect(() => {
    getJuniperHotels2(queryHotel);
  }, [queryHotel, getJuniperHotels2]);

  return (
    <>
      {loading ? (
        <>
          <div className="flex justify-center">
            <Anicarga />
          </div>
        </>
      ) : (
        <>
          <div className="mx-auto flex gap-10 my-10 max-w-[1338.46px]">
            <div className="flex flex-col gap-4">
              <HotelSearch />
            </div>
            <div className="w-full flex flex-col gap-8">
              <HotelListing hotels={mocksHotel} />
            </div>
          </div>
        </>
      )}
    </>
  );
}
