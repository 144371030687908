import React from "react";
import test_user from "../../iconos/test_user.png";
import MenuLink from "./MenuLink";
import cog from "../../iconos/configIcon.png";
import help from "../../iconos/help.svg";
export default function UserPanel({ isHovered, userData, tourOP }) {
  return (
    <div className=" flex flex-col justify-between ">
      <MenuLink
        link={"profile/users"}
        label={"Configuración"}
        icon={cog}
        isHovered={isHovered}
      />
      <MenuLink
        link={"config/faq"}
        label={"Ayuda"}
        icon={help}
        isHovered={isHovered}
      />
      <ul className="flex flex-col gap-8 mt-4">
        {tourOP?.logo?.length > 2 && (
          <img
            src={tourOP?.logo}
            alt={tourOP?.name}
            className="max-h-12 mx-auto"
          />
        )}
        <li className="flex flex-col gap-1">
          <p className="text-xs text-[#333533] ml-5">V1.1</p>
          <div className="w-full h-[1px] my-1 mt-3 mb-6 bg-[#CECECE]" />
        </li>
      </ul>
      {/* user */}
      <div className="flex h-[52px] gap-3 mt-4 group/options ">
        <img
          src={test_user}
          alt="user_image"
          className="w-[56px] h-[52px] -mt-6  left-4 bottom-8"
        />
        <p
          className={`grid items-center text-xl font-medium transition-[width] duration-300 ease-in-out origin-left -mt-14  ${
            isHovered ? "scale-x-100" : "scale-x-0"
          }`}
        >
          {userData?.username || "Usuario"}
        </p>
      </div>
    </div>
  );
}
