import axios from "axios";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useLocationQuery } from "./useLocationQuery";
import QueryString from "qs";
import _ from "lodash";

const formatDate = (date) => {
  const [year, month, day] = date.split("-");
  return [day, month, year].join("/");
};

const translateQuery = (provider, parsedHash, returnParams) => {
  const {
    cityName,
    departureDate,
    returnDate,
    adults,
    children,
    infants,
    roomQuantity,
    priceRange,
  } = parsedHash;

  const query = [];

  if (cityName) {
    query.push(`${returnParams.city}="${cityName}"`);
  }
  if (departureDate) {
    query.push(`${returnParams.departure}="${departureDate}"`);
  }
  if (returnDate) {
    query.push(`${returnParams.return}="${returnDate}"`);
  }
  if (adults && provider === "fod") {
    query.push(`${returnParams.adults}="${adults}"`);
  }

  if (provider === "juniper") {
    const paxArray = [];
    const setPaxArray = (type, age) => {
      for (let i = 0; i < Number(type); i++) {
        paxArray.push(age);
      }
    };

    adults && setPaxArray(adults, 30);
    children && setPaxArray(children, 12);
    infants && setPaxArray(infants, 3);

    query.push(`${returnParams.passengers}=${JSON.stringify(paxArray)}`);
  }

  return query.join("&");
};

export const useGetHotels = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const { parsedHash } = useLocationQuery();
  const [loading, setLoading] = useState(false);
  const [hotels, setHotels] = useState([]);

  const querys = QueryString.stringify(
    _.omitBy({ ...parsedHash }, (a) => a !== 0 && !a)
  ).replace(/\s/g, "");

  // const getAmadeusHotels = async () => {
  //   setLoading(true);
  //   return await axios
  //     .get(
  //       BASE_URL +
  //         "v2/hotels/amadeus/all?" +
  //         (parsedHash?.cityCode
  //           ? "cityCode=" + parsedHash.cityCode + "&"
  //           : "") +
  //         (parsedHash?.departureDate
  //           ? "checkInDate=" + parsedHash.departureDate + "&"
  //           : "") +
  //         (parsedHash?.returnDate
  //           ? "checkOutDate=" + parsedHash.returnDate + "&"
  //           : "") +
  //         (parsedHash?.adults ? "adults=" + parsedHash.adults + "&" : "") +
  //         (parsedHash?.roomQuantity
  //           ? "roomQuantity=" + parsedHash.roomQuantity + "&"
  //           : "") +
  //         (parsedHash?.priceRange
  //           ? "priceRange=" + parsedHash.priceRange + "&"
  //           : "")
  //     )
  //     .then((e) => {
  //       console.log("hotels response", e.data.response);
  //       setHotels(e.data.response.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       // console.log(JSON.parse(e.request.response));
  //       // return JSON.parse(e.request.response);
  //     })
  //     .finally(() => setLoading(false));
  // };

  // const getFullHotelInfo = async ({ hotelIdArray }) => {
  //   const hotelIdsQuery = `[${hotelIdArray.map((id) => `${id}`)}]`;
  //   console.log("hotelIdsQuery", hotelIdsQuery);
  //   return await axios
  //     .get(BASE_URL + "v2/hotels/detail?hotelIds=" + hotelIdsQuery)
  //     .then((e) => {
  //       console.log("getFullHotelInfo", e);
  //       setHotels(e.data.response);
  //     });
  // };

  const getJuniperHotels = async () => {
    setLoading(true);
    const juniperQuery =
      translateQuery("juniper", parsedHash, {
        passengers: "Pax",
        departure: "StartDate",
        return: "EndDate",
      }) + '&City="palma"&CountryOfResidence="AR"&ShowHotelInfo=true';

    return await axios
      .get(BASE_URL + "v2/hotels/juniper?" + juniperQuery)
      .then((e) => {
        // console.log("hotels response", e.data.result);
        // console.log(
        //   "hotels response",
        //   e.data.result?.["soap:Envelope"]?.["soap:Body"]
        // );
        const response = e.data.result?.["soap:Envelope"]?.["soap:Body"]?.[0];

        if (response?.HotelAvailResponse?.[0]?.AvailabilityRS?.[0]?.Errors) {
          setHotels([]);
          return;
        }
        setHotels(
          response?.HotelAvailResponse?.[0]?.AvailabilityRS?.[0]?.Results?.[0]
            ?.HotelResult
        );
      })
      .catch((e) => {
        console.log(e);
        // console.log(JSON.parse(e.request.response));
        // return JSON.parse(e.request.response);
      })
      .finally(() => setLoading(false));
  };

  const getJuniperHotels2 = useCallback(
    async (juniperQuery) => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BASE_URL}v2/hotels/juniper?${juniperQuery}`
        );
        const response2 =
          response?.data?.result?.["soap:Envelope"]?.["soap:Body"]?.[0];
        const hotels =
          response2?.HotelAvailResponse?.[0]?.AvailabilityRS?.[0]?.Results?.[0]
            ?.HotelResult || [];
        setHotels(hotels);
      } catch (error) {
        console.error("Error fetching hotels:", error);
        setHotels([]);
      } finally {
        setLoading(false);
      }
    },
    [BASE_URL]
  );

  // useEffect(() => {
  //   if (!querys) return;
  //   console.log(querys);
  //   // getAmadeusHotels();
  // }, [querys]);

  return {
    loading,
    hotels,
    getJuniperHotels2,
    // getAmadeusHotels,
    getJuniperHotels,
  };
};
